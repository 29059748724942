import { isEmpty, omitBy } from 'lodash';
import {
  getRequest,
  minorUnitAmount,
  paymentMethodProvider,
  postRequest,
} from 'apps/commerce/popup_checkout/util';
import { trackEvent } from 'apps/commerce/popup_checkout/googleAnalytics';

// Stripe-ish
interface StripePaymentPayload extends PaymentPayload {
  provider: 'stripe' | 'kajabi_payments' | null; // paymentMethodProvider can return null
  detail: {
    paymentMethodId: string;
    paymentMethodType: string;
  };
}

interface PopupCheckoutPayload {
  offerToken: string;
  optIn: boolean;
  member: any;
  extraContactInformation?: Object;
  includeOrderBump?: boolean;
  couponCode?: string;
  payment?: PaymentPayload;
  quantity?: number;
}

interface PaymentPayload {
  provider: string | null; // paymentMethodProvider can return null
  offerPriceOverrideInCents?: number | '';
  consentToStorePaymentMethod?: boolean;
}

interface PaypalPaymentPayload extends PaymentPayload {
  provider: 'paypal';
  detail: {
    payerId: any;
    orderId: any;
  };
}

const handleCheckoutError = (setValue: Function, setError: Function, error: any) => {
  setValue('isPaymentInProgress', false);
  setError('payment', { type: 'manual', message: error.message });
};
const handleGetCheckoutStatus = (
  setValue: Function,
  setError: Function,
  redirectToFlow: Function,
  data: any,
  statusResponse: any,
  resolve: Function,
) => {
  const checkoutStatus = statusResponse?.data?.data?.status;
  if (checkoutStatus === 'error') {
    const error = new Error(statusResponse.data.data.message);
    handleCheckoutError(setValue, setError, error);
    resolve(error);
  } else {
    redirectToFlow(statusResponse);
    trackEvent('purchase', data);
    resolve();
  }
};

const getCheckoutStatus = (
  setValue: Function,
  setError: Function,
  handleHttpError: Function,
  redirectToFlow: Function,
  data: any,
  values: any,
  offerCheckoutId: string,
  resolve: Function,
  reject: Function,
) => {
  const checkoutStatusUrl = `/api/checkout/${offerCheckoutId}/status`;
  const pollStatus = () => {
    getRequest(checkoutStatusUrl)
      .then((response) => {
        const checkoutStatus = response.data.data.status;
        const isCheckoutComplete =
          checkoutStatus === 'success' ||
          checkoutStatus === 'error' ||
          checkoutStatus === 'requires_confirmation';
        if (isCheckoutComplete) {
          handleGetCheckoutStatus(setValue, setError, redirectToFlow, data, response, resolve);
          return;
        }
        // poll again in 3 seconds
        setTimeout(pollStatus, 3000);
      })
      .catch((error) => {
        handleHttpError(error);
      });
  };
  pollStatus();
};

export const checkoutHandler = async (
  setValue: Function,
  getValues: Function,
  setError: Function,
  isOfferFree: Boolean,
  showToast: Function,
  t: Function,
  data: any,
  redirectToFlow: Function,
  handleHttpError: Function,
  showSaveCardCheckbox: Boolean,
) => {
  setValue('isPaymentInProgress', true);
  const {
    price,
    elements,
    stripe,
    offer,
    optin,
    email,
    nameRequired,
    phoneRequired,
    name,
    phone,
    addressRequired,
    address,
    collectTaxId,
    taxId,
    member,
    paypalPayerId,
    paypalOrderId,
    saveCard,
    customFieldValues,
    orderBumpActive,
    paymentProvider,
    priceOverrideRequired,
    priceOverride,
    useSavedCard,
    coupon,
    quantity,
    serviceAgreementChecked,
    giftDetails,
  } = getValues();

  const priceOverrideInCents =
    !data || priceOverride === '' ? '' : minorUnitAmount(priceOverride, data.price.exponent);
  const paypalUsed = !!(paypalPayerId || paypalOrderId) && paymentProvider === 'external_paypal';
  const includePriceOverride = priceOverrideRequired && priceOverrideInCents > price.amount;
  const filteredCustomFieldValues = customFieldValues && omitBy(customFieldValues, isEmpty);
  const extraContactInformationRequired =
    phoneRequired || addressRequired || collectTaxId || filteredCustomFieldValues;

  const payload: PopupCheckoutPayload = {
    offerToken: offer.token,
    optIn: optin || false,
    serviceAgreement: serviceAgreementChecked,
    member: {
      email: email || member.email,
      ...(nameRequired && { name }),
    },
    ...(extraContactInformationRequired && {
      extraContactInformation: {
        ...(phoneRequired && { phoneNumber: phone }),
        ...(collectTaxId && { businessNumber: taxId }),
        ...(filteredCustomFieldValues && { ...filteredCustomFieldValues }),
        address_line_1: address.addressLine1,
        address_line_2: address.addressLine2,
        addressCity: address.city,
        addressZip: address.zip,
        addressCountry: address.country,
        ...(address.subdivisionRequired && { addressState: address.subdivision }),
      },
    }),
    ...(giftDetails && { giftDetails }),
  };

  if (isOfferFree) {
    // no payment details required
  } else if (paypalUsed) {
    const paypalPayment: PaypalPaymentPayload = {
      provider: 'paypal',
      detail: {
        payerId: paypalPayerId,
        orderId: paypalOrderId,
      },
    };
    payload.payment = paypalPayment;
  } else if (stripe) {
    if (useSavedCard) {
      const stripePayment: StripePaymentPayload = {
        provider: paymentMethodProvider(offer),
        detail: {
          paymentMethodId: member.savedCardDetails.paymentMethodId,
          paymentMethodType: 'card',
        },
      };
      payload.payment = stripePayment;
    } else {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            email: email || member.email,
          },
        },
      });
      if (error) {
        setValue('isPaymentInProgress', false);
        if (error.code === 'incomplete') {
          // we prevent the user from submitting incomplete card details, however,
          // in the case of apple pay, you can wind up in this state if the user
          // dismisses the apple pay modal without completing the payment...
          // it's not really an error, so we just return here
          return;
        }
        showToast({ title: t('messages.something_went_wrong'), type: 'danger' });
        return;
      }
      const stripePayment: StripePaymentPayload = {
        provider: paymentMethodProvider(offer),
        detail: {
          paymentMethodId: paymentMethod.id,
          paymentMethodType: paymentMethod.type,
        },
      };
      payload.payment = stripePayment;
    }
  }
  if (includePriceOverride) {
    // @ts-ignore
    payload.payment.offerPriceOverrideInCents = priceOverrideInCents;
  }

  if (showSaveCardCheckbox && payload.payment && paymentProvider === 'card') {
    payload.payment.consentToStorePaymentMethod = saveCard;
  }

  if (coupon) {
    payload.couponCode = coupon.couponCode;
  }

  if (quantity > 1) {
    payload.quantity = quantity;
  }

  if (orderBumpActive) {
    payload.includeOrderBump = true;
  }

  // We need a promise here as the isSubmitting state is dependent on it
  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    trackEvent('begin_checkout', data);
    postRequest(`/api/offers/${offer.token}/checkout`, payload)
      .then((response) => {
        const { offerCheckoutId } = response.data.data;
        getCheckoutStatus(
          setValue,
          setError,
          handleHttpError,
          redirectToFlow,
          data,
          getValues(),
          offerCheckoutId,
          resolve,
          reject,
        );
      })
      .catch((error) => {
        handleHttpError(error);
        reject(error);
      });
  });
};
