/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, SageClassnames, Icon, Frame } from '@kajabi/sage-react';
import PropTypes from 'prop-types';
import { localizedPriceFromMinorUnit } from '../util';
import SubscriptionFrequencyText from './SubscriptionFrequencyText';
import { OfferListItemImage } from './OfferListItemImage';

// This OfferListItem component is used to display an offer in the PriceSummary component.
// Offer details consists of two rows: the first row is offer title and price, and the second is offer details.

const OfferListItem = ({
  displayPrice,
  displayOverridePrice,
  price,
  offerTitle,
  checkoutQuantityEnabled,
  isLinkInBioPlan,
  imageSrc,
  quantity = 1,
}) => {
  const { t } = useTranslation();
  const isTrialSubscription = price.subscriptionPlanAttributes?.trialPeriodDays > 0;
  const { priceType, priceStrategy } = price;
  const {
    paymentType,
    interval,
    intervalCount,
    numberOfPayments,
    trialPeriodDays,
    setupFee,
    setupFeeTextOverride,
  } = price.subscriptionPlanAttributes || {};
  const hasSetupFee = setupFee > 0;
  const setupFeeText = setupFeeTextOverride || t('form.setup_fee');
  const isRecurring = priceType === 'recurring';
  const icon = () => {
    if (isLinkInBioPlan) {
      return Icon.ICONS.KAJABI_FILLED;
    }
    if (isRecurring) {
      return paymentType === 'subscription' ? Icon.ICONS.SUBSCRIPTIONS : Icon.ICONS.PRODUCT;
    }
    return priceStrategy === 'fixed' ? Icon.ICONS.PRODUCT : Icon.ICONS.GRANT_OFFER;
  };

  const showPrice = checkoutQuantityEnabled
    ? localizedPriceFromMinorUnit(price.currency, price.amount * quantity, price.exponent)
    : displayOverridePrice || displayPrice;

  return (
    <>
      <Frame direction={Frame.DIRECTIONS.HORIZONTAL} gap={Frame.GAPS.SM} className="price-detail">
        <OfferListItemImage imageSrc={imageSrc} icon={icon()} />
        <Frame width="flex" gap={Frame.GAPS.NONE} align={Frame.ALIGNMENTS.CENTER_SPREAD}>
          {/* offer title and price */}
          <Frame
            direction={Frame.DIRECTIONS.HORIZONTAL}
            align={Frame.ALIGNMENTS.CENTER_SPREAD}
            gap={Frame.GAPS.SM}
            className="price-detail"
          >
            <div className="price-detail-offer-title">
              <h5
                className={`
                t-sage--truncate
                ${SageClassnames.TYPE.BODY_SEMI}
                ${SageClassnames.TYPE.HEADING_5}
              `}
              >
                {offerTitle}
              </h5>
              {quantity > 1 && (
                <span
                  className={`
                  ${SageClassnames.TYPE.BODY_SMALL}
                  ${SageClassnames.TYPE_COLORS.CHARCOAL_100}
                `}
                >
                  ({quantity} {t('form.items')})
                </span>
              )}
            </div>
            <p className={`${SageClassnames.TYPE.BODY_SEMI}`}>{showPrice}</p>
          </Frame>
          {/* subscription/multipay offer details */}
          {(isTrialSubscription || isRecurring) && (
            <Frame
              direction={Frame.DIRECTIONS.HORIZONTAL}
              align={Frame.ALIGNMENTS.CENTER_SPREAD}
              className="price-detail"
            >
              {/* badge for trialing subscription */}
              {isTrialSubscription && (
                <Badge value={`${trialPeriodDays} ${t('form.trial_days')}`} />
              )}
              {/* recurring interval details */}
              <p className="subscription-interval">
                {paymentType === 'subscription' ? (
                  <SubscriptionFrequencyText intervalCount={intervalCount} interval={interval} />
                ) : (
                  <span>
                    {numberOfPayments} {t(`form.${interval}`).toLowerCase()} {t('form.payments')}
                  </span>
                )}
              </p>
            </Frame>
          )}
        </Frame>
      </Frame>
      {/* setup fee line item */}
      {hasSetupFee && (
        <Frame
          direction={Frame.DIRECTIONS.HORIZONTAL}
          align={Frame.ALIGNMENTS.CENTER_SPREAD}
          gap={Frame.GAPS.SM}
          className={`${SageClassnames.TYPE.BODY_SEMI} price-detail sage-spacer-top-xs`}
        >
          <Frame width="56px" gap={Frame.GAPS.NONE} align={Frame.ALIGNMENTS.TOP_RIGHT}>
            <Icon
              color={Icon.COLORS.CHARCOAL_100}
              icon="downsell"
              size={Icon.SIZES.XL}
              style={{ marginTop: '-8px' }}
            />
          </Frame>
          <span style={{ flex: '1' }}>{setupFeeText}</span>
          <span>{localizedPriceFromMinorUnit(price.currency, setupFee, price.exponent)}</span>
        </Frame>
      )}
    </>
  );
};

OfferListItem.propTypes = {
  displayPrice: PropTypes.string.isRequired,
  displayOverridePrice: PropTypes.string,
  offerTitle: PropTypes.string.isRequired,
  checkoutQuantityEnabled: PropTypes.bool,
  imageSrc: PropTypes.string,
  isLinkInBioPlan: PropTypes.bool,
  quantity: PropTypes.number,
  price: PropTypes.shape({
    priceType: PropTypes.string,
    priceStrategy: PropTypes.string,
    subscriptionPlanAttributes: PropTypes.shape({
      paymentType: PropTypes.string,
      interval: PropTypes.string,
      intervalCount: PropTypes.number,
      numberOfPayments: PropTypes.number,
      trialPeriodDays: PropTypes.number,
      setupFee: PropTypes.number,
      setupFeeTextOverride: PropTypes.string,
    }),
  }).isRequired,
};

OfferListItem.defaultProps = {
  quantity: 1,
  displayOverridePrice: null,
  imageSrc: null,
  isLinkInBioPlan: false,
  checkoutQuantityEnabled: false,
};

export default OfferListItem;
