import React from 'react';
import {
  Button,
  SageClassnames,
  SageTokens,
  Tooltip,
  Description,
  Divider,
  Card,
  Icon,
} from '@kajabi/sage-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { getSiteLocaleWithDash } from '../util';

const GiftOffers = ({ giftIsActive, handleGiftOffer }) => {
  const { t } = useTranslation();
  const { getValues, watch } = useFormContext();
  const isOrderBumpActive = watch('orderBumpActive');
  const buttonText = giftIsActive
    ? t('gift_offers.change_gift_options')
    : t('gift_offers.send_as_gift');

  const giftButtonClasses = giftIsActive ? 'button-change' : '';
  const giftDetails = watch('giftDetails');

  const wrapTooltip = (disableText, children) =>
    disableText ? (
      <Tooltip data-testid="gift-offer-tooltip" content={disableText} position="right">
        <div style={{ display: 'inline-block' }}>{children}</div>
      </Tooltip>
    ) : (
      <>{children}</>
    );
  return (
    <>
      {giftIsActive && (
        <Card className="gift-offers__container">
          <p className={`gift-offers_title ${SageClassnames.TYPE.BODY_SMALL}`}>
            <Icon icon="present" className={SageClassnames.SPACERS.XS_RIGHT} />
            {t('gift_offers.gift_options')}
          </p>
          <Divider />

          <Description
            className="gift-offers_options"
            actionWidth="2fr"
            items={[
              {
                data: giftDetails.recipientEmail,
                title: t('gift_offers.send_to'),
              },
              {
                data: Intl.DateTimeFormat(getSiteLocaleWithDash()).format(
                  giftDetails.giftDeliveryDate,
                ),
                title: t('gift_offers.delivery_date'),
              },
              {
                data: giftDetails.message,
                title: t('gift_offers.message_title'),
              },
            ]}
            layout="inline"
            noDividers
          />
        </Card>
      )}

      <div className={`gift-offers ${SageClassnames.SPACERS.MD_BOTTOM}`}>
        {wrapTooltip(
          isOrderBumpActive ? t('tooltips.send_as_gift_disabled') : null,
          <Button
            data-testid="gift-offers-button"
            disabled={isOrderBumpActive}
            color={Button.COLORS.SECONDARY}
            subtle={giftIsActive}
            icon={giftIsActive ? null : SageTokens.ICONS.PRESENT}
            onClick={handleGiftOffer}
            className={giftButtonClasses}
          >
            {buttonText}
          </Button>,
        )}
      </div>
    </>
  );
};

GiftOffers.propTypes = {
  giftIsActive: PropTypes.bool,
  handleGiftOffer: PropTypes.func,
};

GiftOffers.defaultProps = {
  giftIsActive: false,
  handleGiftOffer: () => {},
};

export default GiftOffers;
